.Details {
    padding: 40px 0;
    font-family: Czizh;
    font-size: 22px;
}

.Details__subtitle {
    margin: 20px;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
}

.Details__text {
    margin: 20px;
    text-align: center;
}