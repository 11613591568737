.Footer {
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 100px 0;
    font-family: Vetrino;
    /* animation: v 1s 1 cubic-bezier(.215, .61, .355, 1) 4s backwards; */
}

.Footer__title {
    font-size: 72px;
    margin-bottom: 60px;
}

.Footer__subtitle {
    font-size: 200px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .Footer__title {
        font-size: 52px;
        margin-bottom: 50px;
    }
    .Footer__subtitle {
        font-size: 120px;
    }
}