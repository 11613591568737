.Button {
    text-align: center;
    /* margin-bottom: 40px; */
    display: flex;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.Button__in {
    border: 1px solid #e5e6e9;
    border-radius: 12px;
    padding: 10px 20px;
    color: white;
    background: gray;
    font-weight: 500;
    font-size: 26px;
    cursor: pointer;
    min-width: 280px;
}

.Button__in_success {
    background: seagreen;
}

.Button__in_success.Button__in:hover {
    background-color: seagreen;
}