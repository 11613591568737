.Chevron {
    display: flex;
    align-items: center;
    height: 15vh;
    width: 100%;
    position: relative;
    bottom: 0;
}

.Chevron__item {
    position: relative;
    left: calc(50% - 13px);
    bottom: 10px;
    display: block;
    text-align: center;
    font-size: 20px;
    width: 26px;
    height: 26px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    transform: translate(-50%, 0%) rotate(45deg);
    opacity: 0;
    animation: fade_move_down 2s ease-in-out 3.5s infinite;
}

@keyframes fade_move_down {
    0% {
        transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .Chevron {
        height: 12vh;
    }
}