.Schedule {
    margin: 40px 0;
}

.Schedule__container {
    display: flex;
    justify-content: center
}

.Schedule__title {
    font-family: Czizh;
    font-size: 48px;
    text-align: center;
    margin-bottom: 40px;
}

.Schedule__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.Schedule__item:last-child {
    margin-bottom: 0px;
}

.Schedule__item_icon {
    margin-right: 10px;
}

.Schedule__item_text {
    font-family: Beer money;
    font-size: 22px;
}

.Schedule__item_time {
    margin-right: 100px;
}

@media (max-width: 768px) {
    .Schedule {
        margin: 20px 40px;
    }

    .Schedule__item_time {
        margin-right: 20px;
    }
}