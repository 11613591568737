body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Beer money';
  src: local('Beer money'),
    url('./fonts/beer_money.ttf') format('truetype');
}

@font-face {
  font-family: 'Czizh';
  src: local('Czizh'),
    url('./fonts/Czizh.ttf') format('truetype');
}

@font-face {
  font-family: 'Vetrino';
  src: local('Vetrino'),
    url('./fonts/Vetrino.ttf') format('truetype');
}