.Container {
  max-width: 800px;
  height: 100%;
  margin: auto;
  overflow: hidden;
  font-family: Vetrino;
  user-select: none;
}

.Animation {
  animation: v 1s 1 cubic-bezier(.215, .61, .355, 1) 4s backwards;
}

.Container__second {
  max-width: 800px;
  height: 100%;
  margin: auto;
  overflow: hidden;
  font-family: Vetrino;
  user-select: none;
  /* padding: 40px 0; */
}

.Title {
  font-family: Vetrino;
  font-size: 48px;
  text-align: center;
  margin-bottom: 40px;
}

.Background__alternative {
  background-color: #EDEEF0;
}

.Background__image {
  background-image: url('https://sharipovs.ru/img/bg.png');
  background-size: cover;
}

.Background__image-2 {
  background-image: url('https://sharipovs.ru/img/bg-2.png');
  background-size: cover;
  background-position: top;
}

.Background__svg {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.6501C11.69 21.6501 11.39 21.6101 11.14 21.5201C7.32 20.2101 1.25 15.5601 1.25 8.6901C1.25 5.1901 4.08 2.3501 7.56 2.3501C9.25 2.3501 10.83 3.0101 12 4.1901C13.17 3.0101 14.75 2.3501 16.44 2.3501C19.92 2.3501 22.75 5.2001 22.75 8.6901C22.75 15.5701 16.68 20.2101 12.86 21.5201C12.61 21.6101 12.31 21.6501 12 21.6501ZM7.56 3.8501C4.91 3.8501 2.75 6.0201 2.75 8.6901C2.75 15.5201 9.32 19.3201 11.63 20.1101C11.81 20.1701 12.2 20.1701 12.38 20.1101C14.68 19.3201 21.26 15.5301 21.26 8.6901C21.26 6.0201 19.1 3.8501 16.45 3.8501C14.93 3.8501 13.52 4.5601 12.61 5.7901C12.33 6.1701 11.69 6.1701 11.41 5.7901C10.48 4.5501 9.08 3.8501 7.56 3.8501Z' fill='%23EDEEF0'/%3E%3C/svg%3E%0A");
  background-repeat: repeat;
  /* padding: 40px 0; */
  background-size: 80px;
  /* margin-bottom: 40px; */
}

@keyframes v {
  0% {
    opacity: 0;
    display: none;
  }

  to {
    opacity: 1;
    display: block;
  }
}



@media (max-width: 768px) {
  .Background__image {
    padding: 80px 0;
  }

  .Background__svg {
    background-size: 64px;
  }

  .Background__image-2 {
    background-position: 42%;
  }
}