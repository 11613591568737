.Welcome {
    width: 100%;
    text-align: center;
    padding: 40px 0;
}

.Welcome__text {
    font-family: Czizh;
    font-size: 22px;
    margin: 0 20px 40px;
    white-space: break-spaces;
}

.Welcome__subtitle {
    font-size: 32px;
}