.Header {
    position: relative;
    font-size: 28px;
    font-family: Vetrino;
    letter-spacing: 0.2em;
    color: #333;
    width: 100%;
    height: 70vh;
    line-height: 1.8;
    
  }
  
  .Header__background {
    position: relative;
    width: 90%;
    height: 100%;
    margin: auto;
    background-image: url("https://weddingpost.ru/cabinet/constructor/plate/952/upl6374.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 40%;
    animation: c 2s 1 cubic-bezier(.215, .61, .355, 1) 1s backwards;
  }
  
  .Header__textblock_first {
    position: absolute;
    top: 13vh;
    left: 30px;
    animation: z 2.5s 1 cubic-bezier(.215, .61, .355, 1) .5s backwards;
  }
  
  .Header__textblock_second {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: absolute;
    top: 45vh;
    right: 0;
    margin-left: 20px;
    margin-right: 10px;
    animation: d 1s 1 cubic-bezier(.215, .61, .355, 1) 1.5s backwards;
  }
  
  .Header__textblock_third {
    animation: d 1s 1 cubic-bezier(.215, .61, .355, 1) 2s backwards;
  }
  
  @keyframes z {
    0% {
      opacity: 0;
      transform: scale(1.5);
      left: 100px;
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes d {
    0% {
      opacity: 0;
      transform: scale(1.5);
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes c {
    0% {
      opacity: 0;
      transform: scale(0)
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @media (max-width: 768px) {
    .Header {
      font-size: 13px;
      height: 65vh;
    }
  }